<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtrar"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="voters"
          :server-items-length="totalVoters"
          :loading="loading"
          fixed-header
          height="50vh"
          mobile-breakpoint="200"
          :search="search"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30]
          }"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.identification">
                <td>{{ item.identification }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.factor }}</td>
                <td>
                  <v-icon
                    large
                    :color="status(item.email_status)[0]"
                    v-if="item.apt"
                  >
                    {{ status(item.email_status)[1] }}
                  </v-icon>
                </td>
                <td>
                  <v-icon v-if="!item.apt" color="black" large>
                    mdi-close-circle
                  </v-icon>
                  <v-icon v-else-if="item.voted" color="green" large>
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else color="red" large>
                    mdi-close-circle
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import elections from "@/services/elections";

export default {
  props: ["election"],
  data: () => ({
    voters: [],
    totalVoters: 0,
    loading: true,
    search: "",
    options: {},
    headers: [
      { text: "Identificación", value: "voter_identification" },
      { text: "Nombre", value: "voter_name" },
      { text: "Correo", value: "voter_email" },
      { text: "Factor", value: "factor", filterable: false },
      { text: "Estado", value: "status", filterable: false },
      { text: "Voto", value: "voted", filterable: false }
    ]
  }),
  watch: {
    election() {
      this.getVoters();
    },
    search() {
      this.getVoters();
    },
    options: {
      handler() {
        if (this.election) {
          this.getVoters();
        }
      },
      deep: true
    }
  },
  methods: {
    getVoters() {
      if (this.election.id != undefined) {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let sort = null;
        sort = sortBy[0] + (sortDesc[0] ? " desc" : " asc");
        let opts = {
          sorts: sort,
          page: page,
          per_page: itemsPerPage,
          q: `voter_identification_or_voter_name_or_voter_email_cont: ${this.search}`
        };
        elections.status(this.election, opts).then(
          response => {
            this.voters = response.data.election_voters;
            this.totalVoters = response.data.pagination.total;
            this.loading = false;
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    status(value) {
      let binary = this.pad((value >>> 0).toString(2), 4);
      let color = "red";
      let icon = "mdi-email-outline";

      if (binary[3] == 1) {
        icon = "mdi-email-outline";
        color = "orange";
      }
      if (binary[2] == 1) {
        icon = "mdi-email-outline";
        color = "orange";
      }
      if (binary[1] == 1) {
        icon = "mdi-email-open";
        color = "green";
      }
      if (binary[0] == 1) {
        icon = "mdi-email-open-outline";
        color = "green";
      }
      if (binary[1] == 1 && binary[0] == 1) {
        icon = "mdi-email-open-multiple-outline";
        color = "green";
      }

      return [color, icon];
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }
  }
};
</script>