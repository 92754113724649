<template>
  <div class="result-election">
    <wizard />
    <h2>{{ election.name }}</h2>
    <componentResults :results="result" :electionId="election.id" />
    <electionStatus :election="election" />
    <div>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('notification')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>

<script>
import loadElection from "@/mixins/loadElection";
import elections from "@/services/elections";

import wizard from "@/components/wizard/wizard.vue";
import componentResults from "@/components/ballots/BallotResults.vue";
import electionStatus from "@/components/elections/Status.vue";

export default {
  mixins: [loadElection],
  components: {
    wizard,
    componentResults,
    electionStatus
  },
  data: () => ({
    result: {
      questions: []
    }
  }),
  watch: {
    election() {
      this.loadResults();
    }
  },
  methods: {
    loadResults() {
      let self = this;
      elections.results(this.election).then(response => {
        self.result = response.data;
      });
    },
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    }
  }
};
</script>