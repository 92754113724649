<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col md="10">
          <h2>
            {{ result.apt_voters_count }} electores hábiles de
            {{ result.voters_count }} electores en el padrón electoral, se han
            emitido {{ result.voted }} votos.
          </h2>
          <h2></h2>
        </v-col>
        <v-col md="2">
          <v-btn color="red" class="mx-auto" rounded dark @click="onDownload()"
            >Descargar</v-btn
          >
        </v-col>
      </v-row>
      <br />
      <v-card flat class="mb-5">
        <div
          v-for="question in result.questions"
          :key="question.id"
          class="mb-7"
        >
          <h2 class="red--text">{{ question.name }}</h2>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6">
                  Votos válidos
                </v-col>
                <v-col cols="12" md="6">
                  ({{ question.valid_votes.quantity }})
                  <strong>{{ question.valid_votes.percentage }}%</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6">
                  Votos nulos o blanco
                </v-col>
                <v-col cols="12" md="6">
                  ({{ question.blank_votes.quantity }})
                  <strong>{{ question.blank_votes.percentage }}%</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <transition-group name="flip-list" tag="ul">
            <li v-for="answer in question.answers" :key="answer.id">
              <p class="ma-0">
                <strong>{{ answer.name }}</strong>
              </p>
              <v-row class="mx-0">
                <v-col md="10" class="pa-0">
                  <v-progress-linear
                    :value="answer.percentage"
                    color="amber"
                    background-color="grey"
                    height="20"
                  ></v-progress-linear>
                </v-col>
                <v-col md="1" class="pa-0">
                  <p class="ma-0 text-right">{{ answer.votes }}</p>
                </v-col>
                <v-col md="1" class="pa-0">
                  <p class="ma-0 text-right">
                    <strong>{{ answer.percentage }}%</strong>
                  </p>
                </v-col>
              </v-row>
            </li>
          </transition-group>
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import elections from "@/services/elections";

export default {
  name: "BallotResults",
  props: ["results", "electionId"],
  data: () => ({
    election: {}
  }),
  watch: {
    electionId() {
      this.connectToSocket();
    },
    results() {
      this.election = this.sortAnswers(this.results);
    }
  },
  channels: {
    ElectionResultChannel: {
      connected() {},
      rejected() {},
      received(data) {
        this.newResult(data);
      },
      disconnected() {}
    }
  },
  computed: {
    result() {
      if (this.election != null) {
        return this.election;
      } else {
        return {
          name: null,
          questions: [{ name: null, answers: [{ name: null, votes: 0 }] }]
        };
      }
    }
  },
  methods: {
    connectToSocket() {
      this.$cable.subscribe({
        channel: "ElectionResultChannel",
        id: this.electionId,
        token: JSON.parse(localStorage.getItem("auth_token"))
      });
    },
    newResult(data) {
      this.election = this.sortAnswers(data);
    },
    sortAnswers(election) {
      election.questions.forEach(question => {
        return question.answers.sort((a, b) => {
          return b.votes - a.votes;
        });
      });
      return election;
    },
    onDownload() {
      if (this.electionId) {
        elections.download(this.electionId).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${this.election.name}.xlsx`);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
    }
  }
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 1s;
}
ul {
  list-style-type: none;
  padding: 0;
  padding-top: 10px;
  position: relative;
}
</style>